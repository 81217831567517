import type { CreditCardConfig, CreditCardType } from '#types/creditCard'

export interface CreditCardLogo {
  id: string
  picture: string
  method: string
}

export const useCreditCards = () => {
  const auth = useAuthStore()
  const acceptedCards = useAppConfig().checkout.creditCards.imageGroup[useCountryCode()]

  const getLogos = (cards?: string[] | CreditCardType[] | CreditCardConfig[]): CreditCardLogo[] => {
    const allowedCards = cards?.filter(Boolean).map((card) => isObject(card)
      ? card
      : {
          id: card,
          card_type: card,
          name: card,
          description: card
        }) as CreditCardConfig[]

    return (allowedCards || acceptedCards).map(({ card_type, id, name }) => ({
      id,
      picture: getCreditCardImage(card_type),
      method: name
    }) as CreditCardLogo)
      .filter(({ id, picture }) => picture && (!auth.isEmployee || id.toLowerCase() !== 'amex'))
  }

  return {
    getLogos
  }
}
